/* -- debug version viewer -- */
.git-version {
    font-family: verdana;
    font-size: 12px;
    position: fixed;
    top: 0;
    width: -moz-available;
    width: -webkit-fill-available;
    margin-left: 50px;
    z-index: 99999999999999999999;
  }

  .git-version #git-details {
    color: #FFF;
    top: -80px;
    left: 0;
    position: absolute;
    height: 80px;
  }

  #git-details table {
    border-collapse: initial;
    background: #FF3A3A;
    background: #FFF;
    color: #000;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 1px 3px #888;
    height: 50px;
    padding: 2px;
  }

  #git-details.open { top: 0px; }

  .git-version td { padding: 2px; }
  .git-version .code { background: #DDDDDD80; padding: 2px 4px; }

  .git-open,
  .git-close {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    min-width: 30px;
    padding: 2px 3px;
    background: #F05133;
    text-decoration:none;
    color: #FFF;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 1px 3px #888;
    top: 0;
    left: 10px;
    position: absolute;
  }

  .git-open:hover, .git-close:hover { text-decoration: none; color: #FFF; background: #D1462C; }

  .git-close { top: 48px; height: 18px; border-radius: 5px; }